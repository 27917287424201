import { http } from './config'
import { encriptar } from './cripto';
const auth = "ebd3a7b5-5c80-44ba-bbd4"

export default {
    listar: () => {
        return http.get(`/clientes/listar/${encriptar(auth)}`)
    },
    incluir: (cliente) => {
        const params = {
            auth: encriptar(auth),
            cliente: cliente
        }
        return http.post('/clientes/incluir', params)
    },
    alterar: (cliente) => {
        const params = {
            auth: encriptar(auth),
            cliente: cliente
        }
        return http.put('/clientes/alterar', params)
    },
    excluir: (id) => {
        return http.delete(`clientes/excluir/${encriptar(auth)}/${encriptar(id)}`)
    },
    pesquisar: (id) => {
        return http.get(`clientes/pesquisar/${encriptar(auth)}/${encriptar(id)}`)
    },
    uploadimage: (id, img) => {
        const formData = new FormData();
        formData.append('imagem', img);
        const headers = { 'Content-Type': 'multipart/form-data' };
        return http.post(`clientes/uploadimage/${encriptar(auth)}/${encriptar(id)}`, formData, { headers })
    }
}