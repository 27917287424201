<template>
  <MostrarDados />
</template>

<script>
import MostrarDados from "../components/MostrarDados.vue"
export default {
  components: {
    MostrarDados
  }
}
</script>
